<template>
  <div>
    <el-row class='table-search row-bg' justify='center' type='flex'>
      <el-col>
        <el-form :inline='true' label-position='right' v-model='searchCondition'>
          <el-form-item>
            <el-input @clear='handleQuery' class='input-with-select' clearable
                      placeholder='请输入红人昵称' v-model='searchStatus.nickname'></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click='handleQuery' type='primary'>查询</el-button>
            <el-button v-if="userPermissions.indexOf('kol_info_delete') !== -1" @click='handelDelete' type='danger'>
              删除
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>

      <!--      <el-col :span="6" v-if="userPermissions.indexOf('kol_info_excel') !== -1">-->
      <!--        <el-button @click="handleAdd('add')" type="primary"-->
      <!--                   v-if="userPermissions.indexOf('kol_info_save') !== -1">添加红人-->
      <!--        </el-button>-->
      <!--        <export-btn :can-export="userPermissions.indexOf('kol_info_excel') !== -1"-->
      <!--                    :lintTitle="'下载模板'"-->
      <!--                    :lintTitle="'下载模板'"-->
      <!--                    @export="downLoad"-->
      <!--                    class="download"-->
      <!--                    ref="refExportLink"/>-->
      <!--        <div class="update download" v-if="userPermissions.indexOf('kol_info_excel') !== -1">-->
      <!--          <el-upload-->
      <!--              :before-upload="beforeUpload"-->
      <!--              :disabled="loadingUpload"-->
      <!--              :file-list="file"-->
      <!--              :headers="uploadHeaders"-->
      <!--              :on-change="handleChange"-->
      <!--              :on-success="importSuccess"-->
      <!--              :show-file-list="false"-->
      <!--              action="/admin_api/kol_info/import"-->
      <!--              class="upload-demo"-->
      <!--              multiple-->
      <!--          >-->
      <!--            <el-button :icon="`${loadingUpload?'el-icon-loading':'el-icon-upload2'}`"-->
      <!--                       :loading="loadingUpload"-->
      <!--                       size="mini" type="primary">批量导入-->
      <!--            </el-button>-->

      <!--          </el-upload>-->
      <!--        </div>-->


      <!--      </el-col>-->

    </el-row>
    <el-row v-if="userPermissions.indexOf('kol_info_list') !== -1">
      <el-col :span='24'>
        <div class='default-table'>
          <el-table :data='tableData' ref='table' border stripe v-loading='loadingStatus'
                    @sort-change='changeTableSort'>
            <el-table-column type='selection' width='45' />
            <el-table-column align='center' label='序号' type='index' width='50' />
            <el-table-column align='center' label='红人昵称' min-width='120' prop='nickname'
                             show-overflow-tooltip sortable>
              <template slot-scope='{row}'>
                <el-link @click='detailPage(row.id)' size='mini'
                         type='primary' v-if="userPermissions.indexOf('kol_info_item') !== -1">
                  <span>{{ row.nickname }}</span>
                </el-link>
              </template>
            </el-table-column>
            <el-table-column align='center' label='个人/机构(名称)' min-width='140px'
                             prop='organ_name'></el-table-column>
            <el-table-column align='center' label='媒介' prop='referrer' sortable />
            <el-table-column align='center' label='资源来源' min-width='100' prop='source'
                             show-overflow-tooltip></el-table-column>
            <el-table-column align='center' label='账号标签' min-width='120' prop='account_label'
                             show-overflow-tooltip>
              <template slot-scope='{row}'>

                <span>{{ row.account_label[0] != 'Array' ? row.account_label.toString() : '' }}</span>
              </template>
            </el-table-column>
            <el-table-column align='center' label='肤质' min-width='100' prop='skin_type'
                             show-overflow-tooltip></el-table-column>
            <el-table-column align='center' label='城市' prop='area' show-overflow-tooltip></el-table-column>
            <el-table-column align='center' label='性别' prop='sex' width='80'>
              <template slot-scope='{row}'>
                <span>{{ row.sex == '1' ? '男' : row.sex == '2' ? '女' : '其他' }}</span>
              </template>
            </el-table-column>
            <el-table-column align='center' label='平台' min-width='100' prop='platform_name' />
            <!--            <el-table-column align="center" label="小红书" min-width="100" prop="platforms.xiaohongshu">-->
            <!--              <template slot-scope="{row}">-->
            <!--                <span>{{ row.platforms && row.platforms.xiaohongshu == '1' ? '有' : '/' }}</span>-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <!--            <el-table-column align="center" label="抖音" prop="platforms.douyin">-->
            <!--              <template slot-scope="{row}">-->
            <!--                <span>{{ row.platforms && row.platforms.douyin == '1' ? '有' : '/' }}</span>-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <!--            <el-table-column align="center" label="微博" prop="platforms.weibo">-->
            <!--              <template slot-scope="{row}">-->
            <!--                <span>{{ row.platforms && row.platforms.weibo == '1' ? '有' : '/' }}</span>-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <!--            <el-table-column align="center" label="快手" prop="platforms.kuaishou">-->
            <!--              <template slot-scope="{row}">-->
            <!--                <span>{{ row.platforms && row.platforms.kuaishou == '1' ? '有' : '/' }}</span>-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <!--            <el-table-column align="center" label="B站" prop="platforms.bilili">-->
            <!--              <template slot-scope="{row}">-->
            <!--                <span>{{ row.platforms && row.platforms.bilibili == '1' ? '有' : '/' }}</span>-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <!--            <el-table-column align="center" label="逛逛" prop="platforms.guangguang">-->
            <!--              <template slot-scope="{row}">-->
            <!--                <span>{{ row.platforms && row.platforms.taobao_gg == '1' ? '有' : '/' }}</span>-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <!--            <el-table-column align="center" label="其他" prop="platforms.other">-->
            <!--              <template slot-scope="{row}">-->
            <!--                <span>{{ row.platforms && row.platforms.other == '1' ? '有' : '/' }}</span>-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <el-table-column align='center' label='配合度' prop='fit'></el-table-column>
            <el-table-column align='center' label='合作次数' prop='co_num'></el-table-column>
            <el-table-column align='center' label='最近一次合作时间' min-width='140px'
                             prop='last_co_time'>
              <template slot-scope='{row}'>
                <span>{{ row.last_co_time == null ? '暂无合作' : row.last_co_time }}</span>
              </template>
            </el-table-column>
            <el-table-column align='center' label='提报次数' prop='r_num'></el-table-column>
            <el-table-column align='center' label='提报成功率(%)' min-width='120px'
                             prop='rs_rate'></el-table-column>
            <el-table-column align='center' label='联系方式' min-width='120' prop='contact_mode.mode'
                             show-overflow-tooltip>
              <template slot-scope='{row}'>
                <span>{{
                    row.contact_mode.mode == '' ? '' : row.contact_mode.mode == 'mobile' ? '手机：' : '微信：'
                  }}{{ row.contact_mode.value ? row.contact_mode.value : '暂无' }}</span>
              </template>
            </el-table-column>
            <el-table-column align='center' label='备注' min-width='200' prop='remark'
                             show-overflow-tooltip></el-table-column>
            <el-table-column align='center' label='录入时间' prop='created_at' show-overflow-tooltip
                             width='140'></el-table-column>
            <el-table-column align='center' label='最后更新时间' prop='updated_at' show-overflow-tooltip
                             width='140'></el-table-column>
            <!--            <el-table-column fixed="right" label="操作">-->
            <!--              <template slot-scope="scope">-->

            <!--&lt;!&ndash;                <el-link @click="handleAdd(scope.row.id)" icon="el-icon-edit" size="mini"&ndash;&gt;-->
            <!--&lt;!&ndash;                         type="primary" v-if="userPermissions.indexOf('kol_info_save') !== -1">修改&ndash;&gt;-->
            <!--&lt;!&ndash;                </el-link>&ndash;&gt;-->
            <!--                &lt;!&ndash;                                <el-link @click="delArtist(scope.row.id)" icon="el-icon-delete" size="mini"&ndash;&gt;-->
            <!--                &lt;!&ndash;                                         type="primary">删除&ndash;&gt;-->
            <!--                &lt;!&ndash;                                </el-link>&ndash;&gt;-->
            <!--                &lt;!&ndash;                                <el-link type="primary" size="mini">导入明细</el-link>&ndash;&gt;-->
            <!--              </template>-->
            <!--            </el-table-column>-->
          </el-table>
        </div>
      </el-col>
    </el-row>
    <!--        分页-->
    <el-row>
      <el-col :span='24' style='text-align: right'>
        <Pagination :limit.sync='pagingData.page_size'
                    :page.sync='pagingData.current_page'
                    :total='pagingData.total'
                    @pagination='getList' />
      </el-col>
    </el-row>

  </div>
</template>

<script>
import ArtistSearch from '@/components/artist/ArtistSearch'
import { mapGetters } from 'vuex'
import ExportBtn from '@/components/export/ExportBtn'
import { getToken } from '@/utils/auth'

export default {
  name: 'KolList',
  components: { ArtistSearch, ExportBtn },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo'])
  },
  data() {
    return {
      //查询对象
      searchCondition: { nickname: '' },
      orderSort: {
        id: 'desc',
        referrer: 'desc',
        nickname: 'desc'
      },//默认排序规则
      pagingData: {
        page_size: 15,
        current_page: 1,
        total: 0
      },
      //总记录数
      total: 0,
      // 表格列表数据
      tableData: [],
      searchStatus: {
        nickname: ''
      },
      loadingStatus: true,
      file: [],
      uploadHeaders: { 'HYPERF-SESSION-ID': null },
      loadingUpload: false
    }
  },
  methods: {
    handleQuery() {
      this.pagingData.current_page = 1
      this.getList()
    },

    detailPage(id) {
      this.$router.push({
        name: 'kol_ArtistIntro',
        params: { id: id }
      })
    },
    handleSearchCondition() {
      let cond = {}
      this.searchStatus.nickname ? cond['nickname'] = this.searchStatus.nickname : ''
      return cond
    },
    async getList() {
      this.loadingStatus = true

      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, {
        page_size: this.pagingData.page_size || 10,
        current_page: this.pagingData.current_page || 1
      }, { order: this.orderSort })
      let { list, page_info } = await this.$api.getexternKolList(searchCondition)
        this.tableData = list
        this.pagingData = page_info
        this.loadingStatus = false

    },

    // handleAdd(id) {
    //   this.$router.push({
    //     name: 'kol_add', params: { id: id }
    //   })
    //
    // },
    // delArtist(id) {
    //   this.$confirm(`确认删除该红人吗？`, '提示', {
    //     type: 'warning',
    //     confirmButtonText: '删除',
    //     confirmButtonClass: 'el-button--danger'
    //   }).then(() => {
    //     this.$api.delArtist({ id: id }).then(res => {
    //       if (res.data === 'ok') {
    //         this.$message.success('删除成功')
    //       } else {
    //         this.$message.success('删除成功')
    //       }
    //       this.getList()
    //     })
    //   }).catch(() => {
    //     this.$message.error('未知错误')
    //   })
    // }
    async downLoad() {
      try {
        var isFileSaverSupported = !!new Blob
        if (isFileSaverSupported) {
          // let searchCondition = this.handleSearchCondition()
          let response = await this.$api.exportKolTemplate()
          let name = `批量导入红人模板`
          this.$refs.refExportLink.exportSaveXlsx(response, name)
        }
      } catch (e) {
        this.$message.warning('浏览器不支持导出文件')
      }
    },
    //导入
    //上传模块
    handleChange(file) {
      this.file = [file]
    },
    importSuccess(response) {
      if (response.code === 200) {
        this.$notify.success(response.data)
        this.submitted = []
        this.loadingUpload = false
        this.loadingStatus = false

        this.showProgress = false
        this.dataList = response.data.list || []
        this.getList()
      } else {
        this.$notify.warning(response.msg)
      }
    },
    beforeUpload() {
      this.loadingUpload = true
      this.loadingStatus = true
      this.uploadHeaders['HYPERF-SESSION-ID'] = getToken()
    },
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.getList()
    },
    async handelDelete() {
      const selection = this.$refs.table.selection
      if (!selection.length) {
        this.$message.warning('请选择数据！')
        return
      }
      this.$confirm(
        `确认删除${selection.length}条KOL信息吗？此操作将同步删除【红人资料库】对应数据`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        .then(async () => {
          const promises = selection.map(_ => {
            return this.$api.deleteKol({ id: _.id })
          })
          await Promise.all(promises)
          this.$message.success('操作成功！')
          await this.getList()
        })

    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style scoped>
.table-search {
  margin: 10px 0;
}


.download {
  font-size: 18px;
  margin-left: 12px;
  color: #ff3176;
  cursor: pointer;
  display: inline-grid!important;
}

</style>
